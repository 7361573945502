body {
  margin: 0;
  background-color: #525659;
  font-family:
    Segoe UI,
    Tahoma,
    sans-serif;
}

.Example input,
.Example button {
  font: inherit;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}

.Example__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}
